<template>
	<div class="wrapper">
		<div class="header" :class="{'is-active': isMenuOpen }">
			<div class="menu-btn" @click="clickMenu">
				<span></span>
				<span></span>
				<span></span>
			</div>
			<div class="user-btn-area">
				<a v-if="login === false" href="/login" class="">Login</a>
				<a v-else-if="login === true" href="/member" class="user-btn" title="メンバーページへ">
					<NuxtImg src="/img/icon-user.svg" />
				</a>
			</div>
			<div class="menu long">
				<ul class="menu-item">
					<li><a href="#hero" v-scroll-to="'#hero'" @click="clickMenu">TOP</a></li>
					<li><a href="#news" v-scroll-to="'#news'" @click="clickMenu">更新情報</a></li>
					<li><a href="#describe" v-scroll-to="'#describe'" @click="clickMenu">INTRODUCTION</a></li>
					<li><a href="#project" v-scroll-to="'#project'" @click="clickMenu">プロジェクト</a></li>
					<li><a href="#detail" v-scroll-to="'#detail'" @click="clickMenu">田植えについて</a></li>
					<li><a href="#event-info" v-scroll-to="'#event-info'" @click="clickMenu">ABOUT EVENT</a></li>
					<li><a href="#schedule" v-scroll-to="'#schedule'" @click="clickMenu">SCHEDULE</a></li>
					<li><a href="#sakagura" v-scroll-to="'#sakagura'" @click="clickMenu">酒蔵紹介</a></li>
					<li><a href="#disclaimer" v-scroll-to="'#disclaimer'" @click="clickMenu">免責事項・規約</a></li>
					<li><a href="#attention" v-scroll-to="'#attention'" @click="clickMenu">注意事項</a></li>
					<li><a href="#faq" v-scroll-to="'#faq'" @click="clickMenu">FAQ</a></li>
					<li>
						<a href="#reserve" v-scroll-to="'#reserve'" @click="clickMenu" class="btn black-gold">
							参加申し込み
						</a>
					</li>
				</ul>
			</div>
		</div>

		<slot></slot>
		
		<div class="loading-page">
			<p>ローディング</p>
		</div>

		<div class="age-verification-page" :class="{'is-open': (login !== true) && !isAgeVerified && !isAgeVerifiedTemp}"><!--is-open-->
			<div class="age-verification">
				<div class="title">
					<p>年齢確認</p>
				</div>
				<div class="text">
					<p>このサイトはお酒に関する情報が含まれています。</p>
					<p>20歳未満の方はご覧いただけません。</p>
				</div>
				<div class="question">
					<p>あなたは20歳以上ですか？</p>
				</div>
				<div class="btn-box">
					<div class="btn" id="r20-test" @click="clickAgeVerified">はい</div>
					<div class="btn gray" @click="clickAgeNotVerified">いいえ</div>
				</div>
				<div class="next-check">
					<label>
						<input type="checkbox" class="checkbox" v-model="isSaveAgeVerified">
						<div class="label-mark"></div>
						<div class="label-text">次回よりチェックを省略</div>
					</label>
					<div class="note">
						<p>※未成年の方と端末を共同利用している場合はチェックを入れないでください</p>
					</div>
				</div>
			</div>
		</div>

		<div class="footer">
			<div class="footer-menu">
				<div class="left">
					<div class="name">
						<NuxtImg src="/img/Shakeme_logo_neon.svg" />
					</div>
					<div class="name">
						<small>日本酒イベント</small>
						<p>蔵人入門　～田植え編～</p>
					</div>
					<div class="text">
						<p>
							<span class="sub">主催者</span>
							<span>井坂酒造店</span>
						</p>
						<p>
							<span class="sub">協力</span>
							<span>株式会社PATURN</span>
						</p>
						<p>
							<span class="sub">お問い合わせ先</span>
							<a href="mailto:contact@shakeme.jp">contact@shakeme.jp</a>
						</p>
						<p>
							<small>※ご返信は通常3営業日以内にさせていただきます。</small>
						</p>
					</div>
				</div>
				<div class="right">
					<ul>
						<li><a href="https://paturn.co.jp/privacypolicy" target="_blank" rel="noopener">プライバシーポリシー</a></li>
						<li><a href="/document/scta" target="_blank" rel="noopener">特定商取引法に基づく表記</a></li>
						<li><a href="/document/termsofuse" target="_blank" rel="noopener">利用規約</a></li>
						<li><a href="#faq" target="_blank" rel="noopener">よくある質問</a></li>
						<li><a href="/document/howtouse2" target="_blank" rel="noopener">使い方</a></li>
					</ul>
				</div>
			</div>
			<div class="copyright">copyright &copy; 2024 PATURN Ltd. All Rights Reserved.</div>
		</div>
	</div>
</template>

<script setup>
	const login = useState('login');

	let isMenuOpen = ref(false)	// メニューの開閉

	const clickMenu = () => {
		isMenuOpen.value = !isMenuOpen.value
	}

	const ageVerified = useAgeVerified();
	const { saveAgeVerified } = ageVerified;
	const { isAgeVerified } = storeToRefs(ageVerified);

	let isAgeVerifiedTemp = ref(false)	// 年齢確認チェック(一時)
	let isSaveAgeVerified = ref(false)	// 年齢確認チェックを保存するかチェック

	const clickAgeVerified = () => {
		if(isSaveAgeVerified.value){
			saveAgeVerified();
		}else{
			isAgeVerifiedTemp.value = true
		}
	}

	const clickAgeNotVerified = () => {
		const url = 'https://www.google.com/'
		window.location.href = url
	}

	useHead({
		bodyAttrs: {
			class: 'event-page'
		}
	})
</script>